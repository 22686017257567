<template>
  <section>
      <div class="content-header">
        <h2>How resilient are you?</h2>
      </div>
      <div class="content-wrapper">
        <p class="mb-4">This Resiliency Quiz, developed by Dr. Brecher, is designed for people to learn more about how resilient they are today and the strategies they can adopt that will enhance their life-long resiliency.</p>
        <!-- QUIZ -->
        <div class="panel panel--orange">
          <p class="text-center" v-if="currentQuestion < 12">{{currentQuestion + 1}}/12</p>
          <transition
            mode="out-in"
            appear
            enter-active-class="animate__animated animate__fadeIn"
            leave-active-class="animate__animated animate__fadeOut"
          >
            <!-- Q1 -->
            <question-block
              v-if="currentQuestion === 0"
              :questionOptions="question_01"
              @quiz-completed="currentQuestion = 1"
              @add-score="incrementScore"
              key="q01"
            >
              <!-- CONTENT -->
              <template v-slot:content>
                <p>I intentionally notice and savour the good things that are happening around me</p>
              </template>
              <!-- FEEDBACK -->
              <template v-slot:feedback>
                <p>Tell another person when you are feeling appreciative of a certain moment, whether it is a laugh with friends or a scene in nature. Studies show that those who share positive feelings with others are happier overall than those who do not. <a href="https://www.michellemcquaid.com/podcast/are-you-reaching-for-gratitude-at-work-podcast-with-robert-emmons/" target="_blank">Listen to Dr. Robert Emmons podcast (35 min) on Gratitude</a>.</p>
              </template>
            </question-block>

            <!-- Q2 -->
            <question-block
              v-if="currentQuestion === 1"
              :questionOptions="question_01"
              @quiz-completed="currentQuestion = 2"
              @add-score="incrementScore"
              key="q02"
            >
              <!-- CONTENT -->
              <template v-slot:content>
                <p>When things are not working out, I forgive myself, see the situation as temporary, and assume that this difficult situation will remain contained and not spill onto other parts of my life.</p>
              </template>
              <!-- FEEDBACK -->
              <template v-slot:feedback>
                <p>Letting go and offering yourself forgiveness can help boost your feelings of wellness and improve your image of yourself. Numerous studies have demonstrated that when people practice self-forgiveness, they experience lower levels of depression and anxiety. <a href="https://www.youtube.com/watch?v=psN1DORYYV0&ab_channel=TED" target="_blank">Watch Brene Brown’s TED talk on Listening To Shame (20mins)</a>.</p>
              </template>
            </question-block>

            <!-- Q3 -->
            <question-block
              v-if="currentQuestion === 2"
              :questionOptions="question_01"
              @quiz-completed="currentQuestion = 3"
              @add-score="incrementScore"
              key="q03"
            >
              <!-- CONTENT -->
              <template v-slot:content>
                <p>I have reframed the meaning of a mistake and now understand that Mistakes = Means I Start to Acquire Knowledge, Experience and Skills</p>
              </template>
              <!-- FEEDBACK -->
              <template v-slot:feedback>
                <p>Resilient people don’t let setbacks or bad events affect other unrelated areas of their lives. For instance, they would say “I’m not very good at this” rather than “I’m no good at anything.” <br>– Martin Seligman, founder of Positive Psychology <br> <a href="https://positivepsychology.com/learned-optimism/" target="_blank">Check out this short article for more information about learned optimism</a>.</p>
              </template>
            </question-block>

            <!-- Q4 -->
            <question-block
              v-if="currentQuestion === 3"
              :questionOptions="question_01"
              @quiz-completed="currentQuestion = 4"
              @add-score="incrementScore"
              key="q04"
            >
              <!-- CONTENT -->
              <template v-slot:content>
                <p>A Growth Mindset is based on understanding that everyone needs to exert effort in order to succeed. This applies to me...</p>
              </template>
              <!-- FEEDBACK -->
              <template v-slot:feedback>
                <p>The promotion of a growth mindset can nurture individuals to learn as they understand that intelligence is malleable. <a href="https://www.youtube.com/watch?v=_X0mgOOSpLU&ab_channel=TED" target="_blank">Check out Carol Dweck’s TED Talk on The Power of Believing That You Can Improve</a>.</p>
              </template>
            </question-block>

            <!-- Q5 -->
            <question-block
              v-if="currentQuestion === 4"
              :questionOptions="question_01"
              @quiz-completed="currentQuestion = 5"
              @add-score="incrementScore"
              key="q05"
            >
              <!-- CONTENT -->
              <template v-slot:content>
                <p>I treat myself with the same compassion and kindness as I treat the people I care about</p>
              </template>
              <!-- FEEDBACK -->
              <template v-slot:feedback>
                <p>Self-compassion means treating yourself with the same understanding and kindness that you would treat someone you truly love. <br>– Christopher Germer, PhD <br> <a href="https://www.tarabrach.com/rain/" target="_blank">Try this guided meditation on self-compassion by Tara Brach (11 mins)</a>.</p>
              </template>
            </question-block>

            <!-- Q6 -->
            <question-block
              v-if="currentQuestion === 5"
              :questionOptions="question_01"
              @quiz-completed="currentQuestion = 6"
              @add-score="incrementScore"
              key="q06"
            >
              <!-- CONTENT -->
              <template v-slot:content>
                <p>I use my inner strengths to stick to my goals and don’t give up no matter how hard things get</p>
              </template>
              <!-- FEEDBACK -->
              <template v-slot:feedback>
                <p>Did you know that goal-setting restructures your brain to make it more effective? <a href="https://greatergood.berkeley.edu/article/item/how_to_choose_goals_that_make_you_come_alive" target="_blank">Read this short article on How to Choose Goals that Make You Come Alive</a>.</p>
              </template>
            </question-block>

            <!-- Q7 -->
            <question-block
              v-if="currentQuestion === 6"
              :questionOptions="question_01"
              @quiz-completed="currentQuestion = 7"
              @add-score="incrementScore"
              key="q07"
            >
              <!-- CONTENT -->
              <template v-slot:content>
                <p>I ensure that I get at least 8 - 9 hours of sleep because I know that it improves my ability to learn and retain information, as well as my health and mood overall</p>
              </template>
              <!-- FEEDBACK -->
              <template v-slot:feedback>
                <p>Here are some techniques for combating sleep problems:</p>
                <ul>
                  <li>Keep a regular sleep/wake schedule</li>
                  <li>Don’t drink or eat caffeine four to six hours before bed and minimize daytime use</li>
                  <li>Avoid alcohol and heavy meals before sleep</li>
                  <li>Get regular exercise</li>
                  <li>Minimize noise, light and excessive hot and cold temperatures where you sleep</li>
                  <li>Develop a regular bedtime and go to bed at the same time each night</li>
                  <li>Try and wake up without an alarm clock</li>
                </ul>
                <p><a href="https://www.ted.com/talks/matt_walker_sleep_is_your_superpower?language=en" target="_blank">Watch this TED talk by sleep scientist Matt Walker on Why Sleep Is Your Superpower (18mins)</a>.</p>
              </template>
            </question-block>

            <!-- Q8 -->
            <question-block
              v-if="currentQuestion === 7"
              :questionOptions="question_01"
              @quiz-completed="currentQuestion = 8"
              @add-score="incrementScore"
              key="q08"
            >
              <!-- CONTENT -->
              <template v-slot:content>
                <p>I believe that I can learn and achieve anything if I use strategies, practice and perseverance to focus on my goals</p>
              </template>
              <!-- FEEDBACK -->
              <template v-slot:feedback>
                <p>Grit is defined as “sticking with things over the very long term until you master them.”
                <a href="https://www.rickhanson.net/being-well-podcast-angela-duckworth-grit/" target="_blank"> Listen to this podcast on Grit (44 min) by Angela Duckworth</a>.</p>
              </template>
            </question-block>

            <!-- Q9 -->
            <question-block
              v-if="currentQuestion === 8"
              :questionOptions="question_01"
              @quiz-completed="currentQuestion = 9"
              @add-score="incrementScore"
              key="q09"
            >
              <!-- CONTENT -->
              <template v-slot:content>
                <p>When I live according to my values and lead from them, I am tapping into my character strengths, and life gets easier when I do</p>
              </template>
              <!-- FEEDBACK -->
              <template v-slot:feedback>
                <p>Research has shown that using your character strengths can help to protect against, manage and overcome problems, improve relationships and enhance health, & well-being.
                <a href="https://www.viacharacter.org/" target="_blank"> Get to know your strengths today</a>.</p>
              </template>
            </question-block>

            <!-- Q10 -->
            <question-block
              v-if="currentQuestion === 9"
              :questionOptions="question_01"
              @quiz-completed="currentQuestion = 10"
              @add-score="incrementScore"
              key="q10"
            >
              <!-- CONTENT -->
              <template v-slot:content>
                <p>Starting a new habit requires getting rid of the first 20 seconds of an obstacle that interferes with easy access to the new behaviour. This applies to me...</p>
              </template>
              <!-- FEEDBACK -->
              <template v-slot:feedback>
                <p>On average, it takes more than 2 months before a new behavior becomes automatic — 66 days to be exact.
                <a href="https://www.michellemcquaid.com/podcast/the-3-easy-steps-for-creating-good-habits-podcast-with-wendy-wood/" target="_blank"> Check out Wendy Woods’ podcast on habit formation</a>.</p>
              </template>
            </question-block>

            <!-- Q11 -->
            <question-block
              v-if="currentQuestion === 10"
              :questionOptions="question_01"
              @quiz-completed="currentQuestion = 11"
              @add-score="incrementScore"
              key="q11"
            >
              <!-- CONTENT -->
              <template v-slot:content>
                <p>Quitting a habit means adding in 20 seconds of an obstacle to make it just a bit harder to give in to the bad habit. This applies to me...</p>
              </template>
              <!-- FEEDBACK -->
              <template v-slot:feedback>
                <p>Instead of trying to stop doing something start doing something else.
                <a href="https://www.youtube.com/watch?v=OMbsGBlpP30&ab_channel=TEDxTalks" target="_blank"> Listen to Charles Duhigg’s The Power of Habit TEDx Talk (6mins)</a>.</p>
              </template>
            </question-block>

            <!-- Q12 -->
            <question-block
              v-if="currentQuestion === 11"
              :questionOptions="question_01"
              @quiz-completed="currentQuestion = 12"
              @add-score="incrementScore"
              key="q12"
            >
              <!-- CONTENT -->
              <template v-slot:content>
                <p>I open my Joy Kit whenever I need it. (Joy Kits are like coping kits- designed to be used when your spirit or mood needs an uplift. Collect photos, music, scents, letters, any reminders of anything that will bring you joy.)</p>
              </template>
              <!-- FEEDBACK -->
              <template v-slot:feedback>
                <p>The items in your Joy Kit will change over time. Trying different coping skills over time is great. And sometimes, what worked for a bit will lose its magic. Then it’s time to find another way to support your joy.
                <a href="https://www.ted.com/talks/bonnie_st_john_be_more_resilient_with_a_first_aid_kit_for_your_attitude" target="_blank"> Watch this video for how to create your own joy kit</a>.</p>
              </template>
            </question-block>

            <!-- FEEDBACK -->
            <div
              v-if="currentQuestion === 12"
              class="panel panel--orange"
            >
              <p>You scored a {{totalScore}}</p>
              <div v-if="totalScore >= 49">
                <p><strong>Very resilient</strong></p>
                <p>Stick to your attitudes and strategies, they are working for you.</p>
              </div>
              <div v-else-if="totalScore >= 37">
                <p><strong>Quite resilient</strong></p>
                <p>You are on top of your game.</p>
              </div>
              <div v-else-if="totalScore >= 25">
                <p><strong>Somewhat resilient</strong></p>
                <p>You are on track but could benefit from additional focus on resources to build resilience (such as the ThriveRU Cultivate your Happiness Workbook).</p>
              </div>
              <div v-else-if="totalScore >= 12">
                <p><strong>Some work to do on nurturing your resiliency </strong></p>
                <p>Think about these 5 key components - mindfulness, gratitude, optimism, self-compassion and grit. As you focus on each you can begin to build your resilience toolkit. Explore this workbook to help you to build your skills to thrive.</p>
              </div>
            </div>
          </transition>
        </div>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'
import QuestionBlock from '@/components/QuestionBlock.vue'

export default {
  name: '01',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
    QuestionBlock
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,

      // QUIZ
      currentQuestion: 0,
      totalScore: 0,
      /**
       * QUESTIONS
       */
      // QUESTION 01
      question_01: [
        { btnText: 'Not at all', score: 1 },
        { btnText: 'Somewhat', score: 2 },
        { btnText: 'Sometimes', score: 3 },
        { btnText: 'Frequently', score: 4 },
        { btnText: 'Always', score: 5 }
      ],
      // QUESTION 02
      question_02: [
        { btnText: 'Yes, protected B documents can be shared through MS Teams' },
        { btnText: 'No, protected B documents cannot be shared through MS Teams', isCorrect: true }
      ],
      // QUESTION 03
      question_03: [
        { btnText: 'Yes, sensitive documents can be disposed of in household recycling bins' },
        { btnText: 'Yes, but only if they shred the documents first and put them in a sealed bag' },
        { btnText: 'No, sensitive documents cannot be disposed of in household recycling bins', isCorrect: true }
      ]
    }
  },

  methods: {
    incrementScore (score) {
      console.log(score)
      this.totalScore += score
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
