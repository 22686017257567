<template>
  <button
    @click="btnClicked"
    class="question__button"
    :class="{selected: isBtnClicked, correct: isBtnCorrect == 'correct', incorrect: isBtnCorrect == 'incorrect'}"
    :disabled="isBtnClicked"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'QuestionButton',
  props: {
    isCorrect: {
      type: Boolean,
      default: false
    },
    quizCompleted: {
      type: Boolean,
      default: false
    },
    feedBackID: {
      type: String,
      default: '#feedback'
    }
  },
  data () {
    return {
      isBtnClicked: false
    }
  },
  methods: {
    btnClicked () {
      this.isBtnClicked = true
      this.$emit('btnClicked')
    }
  },
  computed: {
    isBtnCorrect () {
      if (this.isCorrect && this.quizCompleted) {
        return 'correct'
      } else if (!this.isCorrect && this.quizCompleted) {
        return 'incorrect'
      } else {
        return false
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/*------------------------------------*\
  #PRIMARY COLOURS
\*------------------------------------*/

.question {
  &__content {
    margin-bottom: 1rem;
  }
  &__button {
    width: 100%;
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    color: #000;
    box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.3);
    transition: all 0.3s ease;
    padding: 1rem;
    margin-bottom: .5rem;

    &:hover:enabled {
      // border: 4px solid #000;
      box-shadow: 0px 0px 5px rgba($color: #000000, $alpha: 0.5);
      font-weight: 700;
      // color: #000;
    }

    &:disabled {
      // color: #000;
      opacity: 0.8;
    }

    &.correct {
      // border: 6px solid #84BD00;
      // background-color: #006633;
      // color: #fff;
      font-weight: 700;
    }

    &.incorrect {
      // border: 2px solid #DA291C;
    }

    &.selected {
      // border: 2px solid #fff;
      background-color: #fff;
      color: #000000;
      font-weight: 700;
    }

  }
  &__feedback {
    margin-top: 1rem;
    background-color: #E5EFCF;
    padding: 1rem;
  }
}
</style>
