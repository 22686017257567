<template>
  <div class="question" id="question">
    <div class="question__content">
      <slot name="content"></slot>
    </div>
    <div class="row">
      <div
        class="col-sm"
        v-for="(option, index) in questionOptions" :key="index"
      >
        <!-- v-scroll-to="'#question'" -->
        <question-button
          :isCorrect="option.isCorrect"
          @btnClicked="isBtnClicked = true, isQuizCompleted = true, scrollToFeedback(), checkCorrect(option.score)"
          :disabled="isBtnClicked"
          :class="{disabled: isBtnClicked}"
          :quizCompleted="isQuizCompleted"
        >
          {{ option.btnText }}
        </question-button>
      </div>
    </div>
    <transition
      appear
      enter-active-class="animate__animated animate__fadeInUp"
    >
      <div :id="feedbackID" class="question__feedback" v-if="isQuizCompleted">
        <div class="question__feedback-content">
          <!-- <p><strong>{{isQuizCorrect}}</strong></p> -->
          <slot name="feedback"></slot>
        </div>
        <button
          class="question__submit"
          @click="$emit('quiz-completed')"
        >
          {{btnText}}
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import QuestionButton from './QuestionButton.vue'

// Vue Scroll
import VueScrollTo from 'vue-scrollto'

export default {
  name: 'QuestionBlock',
  components: {
    QuestionButton
  },
  props: {
    questionOptions: Array,
    btnText: {
      type: String,
      default: 'Next question'
    },
    feedbackID: {
      type: String,
      default: 'feedback'
    }
  },
  data () {
    return {
      isBtnClicked: false,
      isQuizCompleted: false,
      isQuizCorrect: 'Incorrect.'
    }
  },
  methods: {
    scrollToFeedback () {
      setTimeout(() => {
        VueScrollTo.scrollTo(`#${this.feedbackID}`, { offset: -70 })
      }, 100)
    },

    checkCorrect (score) {
      this.$emit('add-score', score)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
/*------------------------------------*\
  #PRIMARY COLOURS
\*------------------------------------*/
@import '../scss/colors';

.question {
  // background-color: #F7C9A6;
  // background-image: url('../assets/img/_bg/quote-bkrnd.svg');
  // background-position: bottom;
  // background-size: contain;
  // background-repeat: no-repeat;
  padding: 1rem;
  border-radius: 1rem;
  // min-height: 80vh;
  &__content {
    margin-bottom: 1rem;
    font-size: 1.2rem;
  }
  &__submit {
    min-width: 150px;
    // color: $dark-grey;
    border: none;
    border-radius: 10px;
    background: #fff;
    padding: 10px 30px;
    margin: 2rem auto 1rem;
    display: block;
    width: auto;
    min-width: 150px;
    font-weight: 500;
    position: relative;
    z-index: 1;
    transition: all 0.2s ease;

    &:hover {
      // background-color: $aem-yellow;
      // border: 2px solid $dark-grey;
      color: $dark-grey;
      font-weight: 700;
    }

    &:disabled {
      // opacity: 0.9;
      &:hover {
        pointer-events: none;
      }
    }

    &.correct {
      border: 3px solid #84BD00;
    }

    &.selected {
      // background-color: $aem-yellow;
    }

  }

  &__feedback {
    // background-image: url('../assets/img/_bg/nrc-faded-bkrnd1.jpg');

    // margin-top: 2rem;
    // margin-bottom: -2rem;
    // Make full width *mobile only
    // width: 100vw;
    // position: relative;
    // left: 50%;
    // margin-left: -50vw;
  }

  &__feedback-content {
    margin-top: 1rem;
    margin-bottom: 1rem;
    // color: $dark-grey;
    // background-color: rgba($color: #BED5E0, $alpha: 0.2);
    // padding: 1rem;
  }
}
</style>
